<script setup>
import { computed, ref } from "vue";
import { useStore } from "@/utils/useStore";
import { useMoney } from "@/composables/useMoney";
import i18n from "@/i18n.js";
import moment from "moment";
moment.locale("pt-br");
import {
  translationContractStatus,
  translationSalesStatus,
} from "@/utils/translationStatus";
import {
  GrTag,
  GrCollapsible,
  GrCollapsibleItem,
  GrSkeleton,
} from "@/components/GrComponents";
import contractVersion from "../Modal/contractVersion.vue";
import contractSkipp from "../Modal/contractSkipp.vue";

const store = useStore();
const { formatMoney } = useMoney();

const loading = computed(() => {
  return store.getters["getLoadingDetailContracts"];
});
const contractDetail = computed(() => {
  return store.getters["getContractDetails"];
});
const showSkipButtonComputed = computed(() => {
  return store.getters["getShowSkipButton"];
});
const contract_term = computed(() => {
  return store.getters["getContractTerms"];
});

const actualCollapse = ref(null);

function replaceAmount(amount) {
  return formatMoney(amount, "R$");
}

function replaceDate(date) {
  return moment(date).format("DD/MM/YY");
}

function setActualCollapse(collapse) {
  actualCollapse.value = collapse;
  canShowSkipButton = false
}

function validateTextDateInstallment(status, date) {
  if (status === "paid") {
    return `${i18n.t("views.seller.contracts_new.text_030")} ${replaceDate(
      date
    )}`;
  }

  if (status === "waiting_payment") {
    const currentDate = new Date();
    const paymentDate = new Date(date);

    if (paymentDate > currentDate) {
      return `${i18n.t("views.seller.contracts_new.text_046")} ${replaceDate(
        date
      )}`;
    } else {
      return i18n.t("views.seller.contracts_new.text_047");
    }
  }

  if (status === "refused" || status === "refunded") {
    return `${i18n.t("views.seller.contracts_new.text_048")} ${replaceDate(
      date
    )}`;
  }

  if (status === "paid_externally") {
    return `${i18n.t("views.seller.contracts_new.text_049")} ${replaceDate(
      date
    )}`;
  }

  return "---";
}

let canShowSkipButton = false;

const skipCharge = (index) => {
  // Converte o objeto installments para um array ordenado por ID
  const installments = Object.values(contractDetail.value.installments).sort(
    (a, b) => a.id - b.id
  );

  if (!showSkipButtonComputed.value && canShowSkipButton) return false;

  const currentInstallment = installments[index];

  // Verifica se a parcela atual é 'paid' ou 'paid_externally'
  if (
    currentInstallment &&
    !["paid", "paid_externally"].includes(currentInstallment.status)
  ) {
    canShowSkipButton = true;
    return true;
  }

  return false;
};
</script>

<template>
  <div>
    <div
      class="contract-summary"
      v-if="!loading && contractDetail.installments && contractDetail.contract"
      data-anima="top"
    >
      <div class="contract-icon">
      <img
        src="@/assets/img/icons-contracts/version-contract.svg"
        alt="contracts"
      />
      </div>

      <div class="contract-content">
        <div class="contract-header">
          <div class="contract-header-data">
            <div class="contract-info">
              <p class="contract-version">
                {{ i18n.t("views.seller.contracts_new.text_018") }} V{{
                  contractDetail.contract.version
                }}
              </p>
              <h2 class="contract-installment">
                {{ i18n.t("views.seller.contracts_new.text_027") }}
              </h2>
              <p class="contract-next-charge">
                {{ i18n.t("views.seller.contracts_new.text_028") }}:
                {{ replaceDate(contractDetail.contract.next_payment_date) }}
              </p>
            </div>

            <div class="contract-actions">
              <div id="TeleporRenderModalContractVersion"></div>
              <contractVersion :contractTerm="contract_term">
                <div class="contract-actions-data">
                  <img
                    src="@/assets/img/icons-contracts/view-contract.svg"
                    alt="contracts"
                  />
                  <p>{{ i18n.t("views.seller.contracts_new.text_033") }}</p>
                </div>
              </contractVersion>
            </div>
          </div>
        </div>

        <GrCollapsible>
          <GrCollapsibleItem
            v-for="(
              installment, index
            ) in contractDetail.installments"
            :key="index"
            @change="setActualCollapse"
            :index="index + 1"
          >
            <template #header>
              <div class="custom-header-collaps-content">
                <div class="custom-header-collaps">
                  <GrTag
                    custom-background="var(--primary-50)"
                    class="index-collapse"
                    theme="default"
                  >
                    <div class="index-collapse-container">
                      <div class="index-collapse-item">
                        {{ index + 1 }}
                      </div>
                    </div>
                  </GrTag>
                  <div>
                    <h2 :class="{ pay: installment.status === 'paid' }">
                      {{ index + 1 }}ª
                      {{ i18n.t("views.seller.contracts_new.text_029") }}
                    </h2>
                    <p class="mt-3">
                      {{
                        validateTextDateInstallment(
                          installment.status,
                          installment.expected_date
                        )
                      }}
                    </p>
                    <div :id="'TeleporRenderModalContractSkipp-' + index"></div>
                    <contractSkipp
                      :index="String(index + 1)"
                      :id="installment.id"
                      v-if="skipCharge(String(index + 1))"
                    >
                      <div class="skip-charge">
                        <img
                          src="@/assets/img/icons-contracts/skipp-contract.svg"
                          alt="contracts"
                        />
                        {{ i18n.t("views.seller.contracts_new.modal.text_012") }}
                      </div>
                    </contractSkipp>
                  </div>
                </div>
                <GrTag
                  v-if="
                    !(
                      actualCollapse &&
                      actualCollapse.index == index + 1 &&
                      actualCollapse.value
                    )
                  "
                  :variant="
                    translationContractStatus(installment.status).variant
                  "
                  data-anima="top"
                  theme="default"
                >
                  {{ translationContractStatus(installment.status).text }}
                </GrTag>
              </div>
            </template>
            <template #content>
              <div
                class="container-content"
                v-if="installment.history && installment.history.length"
              >
                <div class="header-content">
                  <h3 class="header-content-item">
                    {{ i18n.t("views.seller.contracts_new.text_011") }}
                  </h3>
                  <h3 class="header-content-item">
                    {{ i18n.t("views.seller.contracts_new.text_031") }}
                  </h3>
                  <h3 class="header-content-item">
                    {{ i18n.t("views.seller.contracts_new.text_032") }}
                  </h3>
                </div>

                <div
                  v-for="(sale, index2) in installment.history"
                  :key="index2"
                  class="row-content"
                >
                  <p class="row-content-item">#{{ sale.id }}</p>
                  <p class="row-content-item">
                    {{ replaceDate(sale.date_sale) }}
                  </p>
                  <p class="row-content-item">
                    {{ replaceAmount(sale.amount) }}
                  </p>
                  <GrTag
                    margin="auto"
                    :variant="translationSalesStatus(sale.status_sale).variant"
                    theme="default"
                  >
                    {{ translationSalesStatus(sale.status_sale).text }}
                  </GrTag>
                </div>
              </div>
              <div v-else class="container-content not-register">
                {{ i18n.t("views.seller.contracts_new.text_045") }}
              </div>
            </template>
          </GrCollapsibleItem>
        </GrCollapsible>
      </div>
    </div>

    <GrSkeleton
      v-else
      class="contract-summary"
      sizeX="100%"
      sizeY="250px"
      radius="10px"
      :delay="3"
    />
  </div>
</template>

<style scoped lang="scss">
.contract-summary {
  display: flex;
  padding-bottom: 48px;
  border-bottom: 1px solid var(--gray-50);

  .contract-icon {
    padding: 0 var(--spacing-16) 0 var(--spacing-16);

    @include size(lg) {
      padding: 0;
    }
  }

  .contract-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);

    .contract-header {
      display: flex;
      align-items: center;

      .contract-header-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: var(--w-full);

        .contract-info {
          display: flex;
          flex-direction: column;
          gap: var(--spacing-1);

          .contract-version {
            color: var(--gray-200);
            font-size: var(--font-xsm);
          }

          .contract-installment {
            color: var(--text);
            font-size: var(--font-3-5xl);
            font-weight: var(--weight-extrabold);
          }

          .contract-next-charge {
            color: var(--gray-200);
            font-size: var(--font-md);
          }
        }

        .contract-actions {
          display: flex;
          gap: var(--spacing-12);

          .contract-actions-data {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            cursor: pointer;

            p {
              font-size: var(--font-md);
              font-weight: var(--weight-semibold);
              line-height: normal;
            }

            &:first-child p {
              color: var(--accent-600);
            }
          }
        }

        @include size(sm) {
          align-items: start;
          flex-direction: column;
          gap: var(--spacing-2);
        }
      }
    }

    .contract-details {
      display: flex;
      justify-content: space-between;

      .contract-labels,
      .contract-values {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-6);
      }

      .contract-labels p,
      .contract-values p {
        color: var(--gray-200);
        font-size: var(--font-base);
      }

      .contract-values p {
        color: var(--text);
        font-weight: var(--weight-semibold);
      }
    }
  }

  @include size(lg) {
    flex-direction: column;
    gap: var(--spacing-2);
  }
}

.pay {
  text-decoration: line-through;
}

.custom-header-collaps-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;

  .custom-header-collaps {
    display: flex;
    align-items: center;
    gap: 32px;

    .index-collapse {
      border-radius: 50%;
      padding: 20px;
      width: var(--w-13);
      height: var(--h-13);
      .index-collapse-container {
        position: relative;
        .index-collapse-item {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: var(--text);
          font-size: var(--font-lg);
          font-weight: var(--weight-bold);
        }
      }
    }

    > div {
      h2 {
        color: var(--text);
        font-size: var(--font-lg);
        font-weight: var(--weight-bold);
      }

      p {
        color: var(--gray-200);
        font-size: var(--font-md);
      }
    }

    .skip-charge {
      img {
        width: var(--w-5);
      }

      display: flex;
      gap: 13px;
      align-items: center;
      color: var(--secondary-500);
      font-size: var(--font-md);
      font-weight: var(--weight-semibold);
      margin-top: 32px;
    }
  }
}
.container-content .row-content GrTag {
  direction: rtl; /* Isso fará com que o conteúdo dentro do GrTag se alinhe da direita para a esquerda */
}

.container-content {
  display: grid;
  gap: 25px;
  padding: 48px 0px 0 82px;

  .header-content {
    display: grid;
    grid-template-columns: minmax(155px, auto) 1fr 1fr 1fr;

    .header-content-item {
      color: var(--text);
      font-size: var(--font-base);
    }
  }

  .row-content {
    display: grid;
    grid-template-columns: minmax(155px, auto) 1fr 1fr 1fr;
    align-items: center;

    .row-content-item {
      color: var(--gray-200);
      font-size: var(--font-md);
    }
  }

  &.not-register {
    font-weight: var(--weight-semibold);
    color: var(--text);
  }
}
</style>
